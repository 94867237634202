.grid-wrapper {
    align-self: center;
    padding: 15px 2px 2px 2px;
    height: 100%;
    width: 100%;
    /*min-height: 100%;*/
}

.chart-wrapper {
   align-self: center;
    padding:0 5px 0 5px;
    width: 100%;
    height: 100%;
    min-height: 400px;
}
